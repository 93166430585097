

import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const timeline = ref([
      {
        timestamp: '2021/1/7',
        title: 'Update Github template',
        content: 'Armour committed 2021/1/7 20:46'
      },
      {
        timestamp: '2021/1/12',
        title: 'Update Github template',
        content: 'Armour committed 2021/1/12 20:46'
      },
      {
        timestamp: '2021/1/19',
        title: 'Build Template',
        content: 'Armour committed 2021/1/9 20:46'
      },
      {
        timestamp: '2021/1/23',
        title: 'Release New Version',
        content: 'Armour committed 2021/1/23 20:46'
      }
    ])

    return { timeline }
  }
})
